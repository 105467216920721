import axiosIns from '@/libs/axios'
import utilsService from './utilsService'

const controller = 'challengeModel'

export default {
  async getAll(filter = {}) {
    return axiosIns
      .get(`${controller}?${utilsService.getQueryParams(filter)}`)
      .then(res => res.data)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
}
