<template>
  <section id="feather-icons">
    <b-form @submit.prevent="fetchModels">
      <div class="icon-search-wrapper my-3 mx-auto">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="icons-search"
              v-model="seachQuery"
              placeholder="Buscar Modelos"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </b-form>
    <div id="icons-container" class="d-flex flex-wrap">
      <b-card
        v-for="model in models.content"
        :key="model.id"
        :class="isSelected(model)"
        class="icon-card cursor-pointer text-center mb-2 mx-50 card-icon-new"
        @click="onSelect(model)"
      >
        <div class="icon-wrapper">
          <feather-icon :icon="model.icon" size="24" />
        </div>
        <b-card-text class="icon-name text-truncate mb-0 mt-1">
          {{ model.name }}
        </b-card-text>
      </b-card>
      <!-- <b-card
        
        class="icon-card cursor-pointer text-center mb-2 mx-50 card-icon-new"
        @click="copyIconName(icon)"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="UsersIcon"
            size="24"
          />
        </div>
        <b-card-text class="icon-name text-truncate mb-0 mt-1">
          Arbo Turma
        </b-card-text>
      </b-card> -->
    </div>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1 button-bottom-fix"
          :disabled="!modelSelected"
          @click="nextPage"
        >
          Editar
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCard,
  BCardText,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import challengeModelService from '@/services/challengeModelService'
import { hideSpinner, showSpinner } from '@/services/spinService'

export default {
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BCardText,
    BInputGroupPrepend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modelSelected: null,
      models: {},
      seachQuery: '',
      icons: [
        'ActivityIcon',
        'AirplayIcon',
        'AlertCircleIcon',
        'AlertOctagonIcon',
        'AlertTriangleIcon',
        'AlignCenterIcon',
        'AlignJustifyIcon',
        'AlignLeftIcon',
        'AlignRightIcon',
        'AnchorIcon',
        'AlignRightIcon',
      ],
    }
  },
  computed: {
    filterIcons() {
      const seachQueryIcon = this.seachQuery.toLowerCase()
      return this.icons.filter(item =>
        item.toLowerCase().includes(seachQueryIcon)
      )
    },
  },
  methods: {
    isSelected(model) {
      return this.modelSelected === model.id ? 'bg-primary text-white' : ''
    },
    nextPage() {
      this.$router.push(`/modelos/${this.modelSelected}/editar`)
    },
    onSelect(model) {
      this.modelSelected = model.id
    },
    prepareRequest(obj) {
      return {
        ...obj,
        page: 1,
        itemsPerPage: 100,
      }
    },
    async fetchModels() {
      showSpinner()
      this.models = await challengeModelService.getAll(
        this.prepareRequest({ name: this.seachQuery })
      )
      hideSpinner()
    },
  },
  mounted() {
    this.fetchModels()
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyIconName = iconName => {
      copy(iconName)

      toast({
        component: ToastificationContent,
        props: {
          title: 'Modelo Selecionado!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }

    return {
      copyIconName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
